<template>
  <div class="card card-custom card-stretch">
    <div class="card-header py-3">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t('user_details.notification_options') }}
        </h3>
      </div>
      <div class="card-toolbar">
        <!--begin::Button-->
        <button
          class="btn btn-success font-weight-bolder"
          @click="submitChanges"
        >
          <i class="fa fa-save"></i> {{ $t('commons.save') }}
        </button>
        <!--end::Button-->
      </div>
    </div>

    <div class="card-body">
      <div class="text-center" v-if="isLoading">{{ $t("commons.data_loading") }}...</div>
      <form class="form" v-else>
        <div class="form-group">
          <label class="font-weight-bold">{{ $t(`user_details.notifications.sms`) }}</label>
          <div class="checkbox-list">
            <label class="checkbox" v-for="(item, key, idx) in notifications.sms" :key="`smsOption-${key}-${idx}`">
              <input type="checkbox" v-model="formData[`sms_${key}`]" />
              <span></span>
              {{ item }}
            </label>
          </div>
        </div>
        <div class="bg bg-secondary h-1px my-5"></div>
        <div class="form-group">
          <label class="font-weight-bold">{{ $t(`user_details.notifications.email`) }}</label>
          <div class="checkbox-list">
            <label class="checkbox" v-for="(item, key, idx) in notifications.email" :key="`emailOption-${key}-${idx}`">
              <input type="checkbox" v-model="formData[`email_${key}`]" />
              <span></span>
              {{ item }}
            </label>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: "NotificationOptions",
  components: {
  },
  data() {
    return {
      formData: {}
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.global.lang,
      permissions: (state) => state.customer.permissions,
      isLoading: (state) => state.userdetails.isLoading,
      user: (state) => state.userdetails.notificationOptions.user,
      notifications: (state) => state.userdetails.notificationOptions.notifications
    }),
  },
  methods: {
    checkPermission(permission) {
      return this.permissions?.some(val => String(val.name) === String(permission));
    },
    submitChanges() {
      console.log(this.formData);
    }
  },
  created() {
    if (this.permissions?.length > 0 && !this.checkPermission("crm.users.show.notifications")) {
      this.$router.push("/no-permission");
      return;
    }
    this.$store.dispatch('userdetails/getNotificationOptionsAction', this.$route.params.id).then(() => {
      if(this.user?.preferences?.notification) {
        this.formData = this.user.preferences.notification;
      }
    })
  }
};
</script>
